
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Politicas extends Vue {
  private mounted() {}

  private routerGo(name: any) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          this.$q.notify({
            message: "Ha ocurrido un error",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
      }
    }
  }
}
