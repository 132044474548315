
import { Component, Vue } from "vue-property-decorator";
import PoliticasComponent from "@/components/global/Politicas.vue";

@Component({
  components: {
    PoliticasComponent
  }
})
export default class PoliticasView extends Vue {}
